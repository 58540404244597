import React from "react"

import Layout from "../../layouts/MainLayout/Layout"
import Seo from "../../config-components/seo"
import Wrapper from "../../components/Wrapper/Wrapper"
import Gallery from "../../components/Gallery/Gallery"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"

const PageGallery = ({ location }) => {
  const query = useStaticQuery(graphql`
    query GalleryQuery {
      allFile(filter: { relativeDirectory: { regex: "/gallery/" } }) {
        edges {
          node {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
              fixed {
                originalName
              }
            }
            relativeDirectory
          }
        }
      }
    }
  `)

  const photos = query.allFile.edges.map((el) => ({
    ...el.node.childImageSharp.original,
    src: el.node.publicURL,
    alt: el.node.relativeDirectory.split("/").slice(-1)[0],
    title: el.node.relativeDirectory.split("/").slice(-1)[0],
  }))

  return (
    <Layout>
      <Header page={location.pathname} />
      <Seo
        title="Катамараны с педалями, водные велосипеды - фото нашей продукции"
        description="Велокатамараны, моторные катамараны и водные велосипеды - фотографии эксплуатации нашей продукции MARINA-M с озер и морей."
        keywords="водный велосипед, катамаран с педалями, моторный катамаран, велокатамаран"
      />
      <Wrapper width={1060}>
        <Gallery photos={photos} />
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default PageGallery
