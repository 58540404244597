import React from "react"
import PhotoGallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import { useGallery } from "./hooks"

const Gallery = ({ photos }) => {
  const { currentImage, viewerIsOpen, openLightbox, closeLightbox } =
    useGallery()

  return (
    <div style={{ width: "100%" }}>
      <PhotoGallery
        margin={5}
        photos={photos}
        direction={"column"}
        onClick={openLightbox}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default Gallery
